import React, { Component, Fragment } from "react";
import check from "check-types";
import arrayFlatten from "array-flatten";
import request from "request-promise";
import moment from "moment";
import {
  Container,
  Divider,
  Dropdown,
  Card,
  Label,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Form,
  Button,
  Message
} from "semantic-ui-react";

import subscriptionIcon from "./assets/np_subscription_456173_A0A0A0.svg";
import invoiceIconBlue from "./assets/np_invoices_2064117_0E6EB8.svg";
import invoiceIconRed from "./assets/np_invoices_2064117_B03060.svg";

moment.locale("fr");

const URI = "https://ed-spi.com/api/";

const SearchForm = ({ onSubmit = () => {}, inputRef = null, value = null }) => (
  <Card.Content>
    <Card.Header>Retrouver mes factures</Card.Header>
    <Card.Description>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>E-mail</label>
          <input
            placeholder="E-mail"
            type="email"
            ref={inputRef}
            defaultValue={value}
          />
        </Form.Field>
        <Button color="teal" type="submit">
          Retrouver mes factures
        </Button>
      </Form>
    </Card.Description>
  </Card.Content>
);

export default class InvoicesWidget extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      email: null,
      response: null
    };
    this.emailField = React.createRef();

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ email: localStorage["spinola-invoices.email"] });
  }

  async onSubmit(...args) {
    const email = this.emailField.current.value;
    localStorage["spinola-invoices.email"] = email;
    this.setState({ email: email.trim().toLowerCase(), response: null });

    try {
      const response = await request({
        uri: URI,
        qs: {
          email
        },
        json: true // Automatically parses the JSON string in the response
      });

      this.setState({ response });
    } catch (err) {
      this.setState({
        response: "Error processing your request: " + err.message
      });
    }
  }

  render() {
    const { email, response } = this.state;
    let content = [];

    const MessageDiv = ({ title, msg }) => (
      <Message negative>
        <Message.Header>{title}</Message.Header>
        <p>
          {/*Avez-vous une autre adresse e-mail?*/}
          {msg}
        </p>
      </Message>
    );

    content.push(
      <SearchForm
        key="form"
        loading={!!email && !response}
        onSubmit={this.onSubmit}
        inputRef={this.emailField}
        value={email}
      />
    );

    if (response) {
      content.push(<Divider key="divider" />);

      if (check.nonEmptyObject(response)) {
        if (check.nonEmptyString(response.error)) {
          content.push(
            <MessageDiv
              title="Erreur serveur"
              msg="Une erreur est apparue sur notre serveur. Nous avons été mis au courant. Merci de réessayer plus tard."
            />
          );
        } else if (!response.customerFound) {
          content.push(
            <MessageDiv
              title={`Aucun client trouvé avec l'e-mail '${email}'`}
              msg="Avez-vous une autre adresse e-mail?"
            />
          );
        } else if (
          !response.invoiceFound ||
          !check.nonEmptyArray(response.data.invoices)
        ) {
          content.push(
            <MessageDiv
              title={`Aucune facture trouvée pour l'e-mail '${email}'`}
              msg="Vous avez bien créé un compte chez nous avec cet e-mail, mais vous n'avez jamais été facturé. Avez-vous une autre adresse e-mail?"
            />
          );
        } else {
          // If response is an object, display the list of products and invoices from it!
          /* const subscriptionsMap = {};
          response.data.forEach(d => {
            if (
              !d ||
              !check.nonEmptyString(d.account) ||
              !d.customer ||
              !d.customer.subscriptions ||
              !check.nonEmptyArray(d.customer.subscriptions.data)
            ) {
              return;
            }

            const account = d.account;

            d.customer.subscriptions.data.forEach(s => {
              const { id } = s;
              subscriptionsMap[`${account}.${id}`] = Object.assign(s, {
                account
              });
            });
          });

          const subscriptions = Object.values(subscriptionsMap);


          const activeSubscriptions = subscriptions.filter(
            s => !s.cancel_at && !s.canceled_at
          );
          const pastSubscriptions = subscriptions.filter(
            s => s.cancel_at || s.canceled_at
          );

          */

          const { subscriptions } = response.data;
          const activeSubscriptions = subscriptions.filter(s => s.active);
          const pastSubscriptions = subscriptions.filter(s => !s.active);

          const Subscription = ({ data }) => {
            const intervalMap = {
              day: "jour",
              week: "semaine",
              month: "mois",
              year: "an"
            };
            const currencyMap = {
              eur: "€",
              usd: "$"
            };
            const interval = intervalMap[data.interval] || "mois";
            const currency = currencyMap[data.currency.toLowerCase()] || "€";

            return (
              <List.Item verticalAlign="middle">
                <Image avatar src={subscriptionIcon} />
                <List.Content verticalAlign="middle">
                  {data.product ? data.product.name : data.id}
                  {" - "}
                  <a
                    href={`mailto:hello@spinolabayknowledge.com?subject=Terminer mon abonnement&body=${encodeURIComponent(
                      `Bonjour,\n\nJ'aimerais mettre fin à mon abonnement ${
                        data.product.name
                      } à ${data.amount /
                        100}€ / mois dès que possible.\nMerci pour votre aide.\n\nCordialement,`
                    )}`}
                    target="_blank"
                    style={{ color: "#A0A0A0" }}
                  >
                    (Annuler)
                  </a>
                </List.Content>
                <List.Content floated="right" verticalAlign="middle">
                  {data.amount / 100}
                  {currency + " / " + interval}
                </List.Content>
              </List.Item>
            );
          };

          let { invoices } = response.data;
          invoices.sort((a, b) => a.invoice.date - b.invoice.date);
          invoices = invoices.filter(i => i.invoice.amount_due > 0);

          const invoicesPerProduct = {};
          invoices.forEach(i => {
            const p = i.products[0].name;

            if (!invoicesPerProduct[p]) {
              invoicesPerProduct[p] = [];
            }

            invoicesPerProduct[p].push(i);
          });

          const productNames = Object.keys(invoicesPerProduct);
          productNames.sort();

          const Invoice = ({ data }) => {
            const currencyMap = {
              eur: "€",
              usd: "$"
            };
            const currency =
              currencyMap[data.invoice.currency.toLowerCase()] || "€";

            return (
              <List.Item verticalAlign="middle">
                <List.Content floated="right">
                  <Button
                    as="a"
                    target="_new"
                    href={data.invoice.hosted_invoice_url}
                  >
                    Voir
                  </Button>
                </List.Content>
                <Image
                  avatar
                  src={data.invoice.paid ? invoiceIconBlue : invoiceIconRed}
                />
                <List.Content verticalAlign="middle">
                  {moment
                    .unix(data.invoice.date)
                    .locale("fr")
                    .format("MMMM YYYY")}{" "}
                  - {data.invoice.amount_due / 100}
                  {currency}
                </List.Content>
              </List.Item>
            );
          };

          const InvoiceCategory = ({ product, invoices }) => (
            <Fragment>
              <Card.Header as="h4">{product}</Card.Header>
              <Card.Description>
                <List>
                  {invoices.map(i => (
                    <Invoice key={i.account + "." + i.invoice.id} data={i} />
                  ))}
                </List>
              </Card.Description>
            </Fragment>
          );

          const activeSubscriptionsElems = activeSubscriptions.map(s => (
            <Subscription key={s.account + "." + s.id} data={s} />
          ));
          const pastSubscriptionsElems = pastSubscriptions.map(s => (
            <Subscription key={s.account + "." + s.id} data={s} />
          ));

          //  const invoices =

          if (check.nonEmptyArray(activeSubscriptions)) {
            content.push(
              <Fragment key="activeSubscriptions">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Abonnements en cours
                </Card.Header>
                <Card.Description>
                  <List>{activeSubscriptionsElems}</List>
                </Card.Description>
              </Fragment>
            );
          }

          if (check.nonEmptyArray(pastSubscriptions)) {
            content.push(
              <Fragment key="pastSubscriptionsElems">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Abonnements expirés
                </Card.Header>
                <Card.Description>
                  <List>{pastSubscriptionsElems}</List>
                </Card.Description>
              </Fragment>
            );
          }

          if (check.nonEmptyArray(invoices)) {
            content.push(
              <Fragment key="pastSubscriptionsElems">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Factures
                </Card.Header>
                <Card.Description>
                  {productNames.map(p => (
                    <InvoiceCategory
                      key={"invoiceCategory_" + p}
                      product={p}
                      invoices={invoicesPerProduct[p]}
                    />
                  ))}
                </Card.Description>
              </Fragment>
            );
          }

          /*
          content.push(
            <Fragment key="resultats">
              <Card.Header>Votre e-mail: {email}</Card.Header>
              <Card.Description>
                <Header as="h2"></Header>
                <pre>{JSON.stringify(response, null, 4)}</pre>
              </Card.Description>
            </Fragment>
          );
          */
        }
      } else {
        content.push(
          <MessageDiv
            title="Erreur serveur"
            msg="Une erreur est apparue sur notre serveur. Nous avons été mis au courant. Merci de réessayer plus tard."
          />
        );
      }
    }

    return (
      <Card fluid>
        <Card.Content>{content}</Card.Content>
      </Card>
    );
  }
}
