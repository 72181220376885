import React from "react";
import logo from "./spinola-logo.png";
import "./App.css";
import "semantic-ui-css/semantic.min.css";

import InvoicesWidget from "./InvoicesWidget";

import {
  Container,
  Divider,
  Dropdown,
  Card,
  Label,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Form,
  Button
} from "semantic-ui-react";

const FixedMenuLayout = () => (
  <div>
    {/*
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item as="a" header>
          <Image
            size="mini"
            circular
            src={logo}
            style={{
              padding: "3px",
              marginRight: "1.5em",
              backgroundColor: "white"
            }}
          />
          Editions Spinola
        </Menu.Item>
      </Container>
    </Menu>*/}

    <Container
      text
      style={{
        marginTop: "1em"
        /* marginTop: "7em"  */
      }}
    >
      <Image
        size="medium"
        centered
        src="https://spinolabayknowledge.com/hosted/images/dc/3775e01fb311e9b64c0d2ac443c957/logo-draft-Editions-Spinola.png"
      />
      {/*<Header as="h1">Editions Spinola</Header>*/}
      <Header as="h2">Centre de facturation</Header>
      <Header as="h3">Qu'est ce que Editions Spinola?</Header>
      <p>
        Editions Spinola est un Centre de Formation en ligne pour les
        entrepreneurs. Nous disposons de plusieurs programmes de formation ayant
        pour objet d'accompagner nos élèves vers le succès dans leur démarche de
        vente en ligne, d'investissement, ou de passage à grande échelle de leur
        projet.
      </p>
      <Header as="h3">
        A quoi correspondent les débits labelisés <Label>ED-SPI.COM</Label> sur
        mon compte bancaire?
      </Header>
      <p>
        Il s'agit très certainement de prélèvements bancaires liés à votre
        inscription à l'une de nos formations. Nous proposons certaines
        formations sous la forme d'achats uniques et d'autres sous la forme
        d'abonnements.
      </p>

      <Header as="h3">
        Comment retrouver les factures liés à ces prélèvements?
      </Header>
      <p>
        Vous pouvez retrouver vos factures à tout moment en utilisant l'outil
        ci-dessous:
      </p>

      <InvoicesWidget />
    </Container>

    <Segment
      inverted
      vertical
      style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
    >
      <Container textAlign="center">
        {/*<Image
          centered
          size="mini"
          src={logo}
          circular
          style={{ backgroundColor: "white", padding: "3px" }}
        />*/}
        <List horizontal inverted divided link size="small">
          <List.Item as="a" href="https://www.editions-spinola.com/">
            Site principal
          </List.Item>
          <List.Item
            as="a"
            href="https://www.editions-spinola.com/home-presentation"
          >
            A propos
          </List.Item>
          <List.Item
            as="a"
            href="https://www.editions-spinola.com/mentions-legales"
          >
            Mentions légales
          </List.Item>
          <List.Item as="a" href="https://spinolabayknowledge.com/acces">
            Espace client
          </List.Item>
          <List.Item as="a" href="https://www.editions-spinola.com/contact">
            Contact
          </List.Item>
        </List>
      </Container>
    </Segment>
  </div>
);

function App() {
  return (
    <FixedMenuLayout />

    /*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hello <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */
  );
}

export default App;
